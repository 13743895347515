/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiError } from './core/ApiError';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { Address } from './models/Address';
export type { Address_ApartmentNo } from './models/Address_ApartmentNo';
export type { Address_City } from './models/Address_City';
export type { Address_CompanyName } from './models/Address_CompanyName';
export type { Address_FirstName } from './models/Address_FirstName';
export type { Address_Floor } from './models/Address_Floor';
export type { Address_LastName } from './models/Address_LastName';
export type { Address_Note } from './models/Address_Note';
export type { Address_PostalCode } from './models/Address_PostalCode';
export type { Address_Street } from './models/Address_Street';
export type { Address_StreetNo } from './models/Address_StreetNo';
export type { Address_TaxId } from './models/Address_TaxId';
export type { AllBanksEnum } from './models/AllBanksEnum';
export type { Amount } from './models/Amount';
export type { AnyAvatar } from './models/AnyAvatar';
export type { AnyWalletCounterParty } from './models/AnyWalletCounterParty';
export type { APIGatewayProxyResult } from './models/APIGatewayProxyResult';
export type { ApmPoint } from './models/ApmPoint';
export type { AppWalletTrx } from './models/AppWalletTrx';
export type { AppWalletTrxExecutorDataBank } from './models/AppWalletTrxExecutorDataBank';
export type { AppWalletTrxExecutorDataMerchant } from './models/AppWalletTrxExecutorDataMerchant';
export type { AppWalletTrxExecutorDataOpenApp } from './models/AppWalletTrxExecutorDataOpenApp';
export type { AppWalletTrxExecutorDataType_BANK } from './models/AppWalletTrxExecutorDataType_BANK';
export type { AppWalletTrxExecutorDataType_MERCHANT } from './models/AppWalletTrxExecutorDataType_MERCHANT';
export type { AppWalletTrxExecutorDataType_OPENAPP } from './models/AppWalletTrxExecutorDataType_OPENAPP';
export type { AppWalletTrxExecutorDataType_USER } from './models/AppWalletTrxExecutorDataType_USER';
export type { AppWalletTrxExecutorDataUser } from './models/AppWalletTrxExecutorDataUser';
export type { AppWalletTrxExecutorDataV4 } from './models/AppWalletTrxExecutorDataV4';
export type { AppWalletTrxParty } from './models/AppWalletTrxParty';
export type { AuthConfirmAndTrustedBrowser } from './models/AuthConfirmAndTrustedBrowser';
export type { AuthConfirmRequest } from './models/AuthConfirmRequest';
export type { AvatarType_COMBINED } from './models/AvatarType_COMBINED';
export type { AvatarType_EMOJI } from './models/AvatarType_EMOJI';
export type { AvatarType_IMAGE } from './models/AvatarType_IMAGE';
export type { AvatarType_NONE } from './models/AvatarType_NONE';
export type { BankTransactionMethod } from './models/BankTransactionMethod';
export type { BankTransactionParty } from './models/BankTransactionParty';
export type { BasicSaneString } from './models/BasicSaneString';
export type { BasketDeliveryOption } from './models/BasketDeliveryOption';
export type { BasketPrice } from './models/BasketPrice';
export type { BasketPriceDiscount } from './models/BasketPriceDiscount';
export type { BasketPriceDiscountError } from './models/BasketPriceDiscountError';
export type { BasketProduct } from './models/BasketProduct';
export type { BenefitialOwner } from './models/BenefitialOwner';
export type { BillingAddressType_COMPANY } from './models/BillingAddressType_COMPANY';
export type { BillingAddressType_PERSONAL } from './models/BillingAddressType_PERSONAL';
export type { BLIKTransactionMethod } from './models/BLIKTransactionMethod';
export type { BLIKTransactionParty } from './models/BLIKTransactionParty';
export type { CardTransactionMethod } from './models/CardTransactionMethod';
export type { CardTransactionParty } from './models/CardTransactionParty';
export type { CategoryName } from './models/CategoryName';
export type { CheckoutConsentInline } from './models/CheckoutConsentInline';
export type { CheckoutConsentUrl } from './models/CheckoutConsentUrl';
export type { CheckoutConsentV2 } from './models/CheckoutConsentV2';
export type { CheckoutDeliverySubtype } from './models/CheckoutDeliverySubtype';
export type { CheckoutDeliveryType } from './models/CheckoutDeliveryType';
export type { CheckoutMerchantDataV3 } from './models/CheckoutMerchantDataV3';
export type { Clause } from './models/Clause';
export type { CombinedAvatar } from './models/CombinedAvatar';
export type { ConsentDefinition } from './models/ConsentDefinition';
export type { ConsentDefinitionInput } from './models/ConsentDefinitionInput';
export type { ConsentDefinitionOptions } from './models/ConsentDefinitionOptions';
export type { ConsentDefinitionPrimaryKey } from './models/ConsentDefinitionPrimaryKey';
export type { ConsentDocumentType } from './models/ConsentDocumentType';
export type { ConsentDocumentType_INLINE } from './models/ConsentDocumentType_INLINE';
export type { ConsentDocumentType_URL } from './models/ConsentDocumentType_URL';
export type { ConsentsBody } from './models/ConsentsBody';
export type { ConsentTranslations } from './models/ConsentTranslations';
export type { ConsentTranslationValues } from './models/ConsentTranslationValues';
export type { ConsentTranslationValuesInline } from './models/ConsentTranslationValuesInline';
export type { ConsentTranslationValuesUrl } from './models/ConsentTranslationValuesUrl';
export type { ConsentType } from './models/ConsentType';
export type { ConsentUsageReason } from './models/ConsentUsageReason';
export type { Country } from './models/Country';
export type { CreateCredentialOneTimeResponse } from './models/CreateCredentialOneTimeResponse';
export type { CreateCredentialRequest } from './models/CreateCredentialRequest';
export type { CreateIntegrationProfile } from './models/CreateIntegrationProfile';
export type { CreateLocationRequest } from './models/CreateLocationRequest';
export type { CreateOrderBillingAddress } from './models/CreateOrderBillingAddress';
export type { CreateOrderDeliveryAdddress } from './models/CreateOrderDeliveryAdddress';
export type { CreateOrUpdateConsentDefinitionRequest } from './models/CreateOrUpdateConsentDefinitionRequest';
export type { CredentialResponse } from './models/CredentialResponse';
export type { CurrencyCode } from './models/CurrencyCode';
export type { DeliveryMethodSuggestionTypes } from './models/DeliveryMethodSuggestionTypes';
export type { DeliveryName } from './models/DeliveryName';
export type { DeliveryOption } from './models/DeliveryOption';
export type { DeliveryOptionConfig } from './models/DeliveryOptionConfig';
export type { DeliveryOptionConfigTranslationModel } from './models/DeliveryOptionConfigTranslationModel';
export type { DeliveryOptionTranslationModel } from './models/DeliveryOptionTranslationModel';
export type { DeliveryOptionTranslations } from './models/DeliveryOptionTranslations';
export type { DeliveryProvider } from './models/DeliveryProvider';
export type { DeliveryProviderConfig } from './models/DeliveryProviderConfig';
export type { DeliveryProviderStrategy } from './models/DeliveryProviderStrategy';
export type { DeliveryStatus } from './models/DeliveryStatus';
export type { DevicePaymentOptions } from './models/DevicePaymentOptions';
export type { Discount } from './models/Discount';
export type { DocumentCreate } from './models/DocumentCreate';
export type { EditMerchantUserRequest } from './models/EditMerchantUserRequest';
export type { ElectronicDeliveryData } from './models/ElectronicDeliveryData';
export type { EmojiAvatar } from './models/EmojiAvatar';
export type { EncryptedLastItem } from './models/EncryptedLastItem';
export type { EqualsClause } from './models/EqualsClause';
export type { ExpectedItemn } from './models/ExpectedItemn';
export type { HistoryItem } from './models/HistoryItem';
export type { IBAN } from './models/IBAN';
export type { ImageAvatar } from './models/ImageAvatar';
export type { InClause } from './models/InClause';
export type { InitUserInviteRequest } from './models/InitUserInviteRequest';
export type { InstantString } from './models/InstantString';
export type { IntegratedBanksEnum } from './models/IntegratedBanksEnum';
export type { IntegrationProfileMerchantAuth } from './models/IntegrationProfileMerchantAuth';
export type { IntegrationProfileMerchantAuth_BasicUsernamePassword } from './models/IntegrationProfileMerchantAuth_BasicUsernamePassword';
export type { IntegrationProfileMerchantAuth_HeaderNameAndHeaderValue } from './models/IntegrationProfileMerchantAuth_HeaderNameAndHeaderValue';
export type { IntegrationProfileMerchantAuth_NoExtraAuth } from './models/IntegrationProfileMerchantAuth_NoExtraAuth';
export type { IntegrationProfileResponse } from './models/IntegrationProfileResponse';
export type { IntegrationProfileUrls } from './models/IntegrationProfileUrls';
export type { LocalDate } from './models/LocalDate';
export type { LocalDateString } from './models/LocalDateString';
export type { LocalDateTime } from './models/LocalDateTime';
export type { Location } from './models/Location';
export type { LogicalClause } from './models/LogicalClause';
export type { LogicalOperator } from './models/LogicalOperator';
export type { MatchClause } from './models/MatchClause';
export type { MerchandiseType } from './models/MerchandiseType';
export type { Merchant } from './models/Merchant';
export type { MerchantAuthInit } from './models/MerchantAuthInit';
export type { MerchantConsentDefinitions } from './models/MerchantConsentDefinitions';
export type { MerchantDeliveryOptions } from './models/MerchantDeliveryOptions';
export type { MerchantDeliveryOptionWithConfig } from './models/MerchantDeliveryOptionWithConfig';
export type { MerchantDeliveryProvider } from './models/MerchantDeliveryProvider';
export type { MerchantDetails } from './models/MerchantDetails';
export type { MerchantInvoice } from './models/MerchantInvoice';
export type { MerchantOnboardingInitRequest } from './models/MerchantOnboardingInitRequest';
export type { MerchantOnboardingInitResponse } from './models/MerchantOnboardingInitResponse';
export type { MerchantOnboardingPrefilledFormData } from './models/MerchantOnboardingPrefilledFormData';
export type { MerchantOnboardingStatus } from './models/MerchantOnboardingStatus';
export type { MerchantOperationalContact } from './models/MerchantOperationalContact';
export type { MerchantOrder } from './models/MerchantOrder';
export type { MerchantRepresentative } from './models/MerchantRepresentative';
export type { MerchantStatus } from './models/MerchantStatus';
export type { MerchantTransactionParty } from './models/MerchantTransactionParty';
export type { MerchantUser } from './models/MerchantUser';
export type { MerchantUserCredentialChange } from './models/MerchantUserCredentialChange';
export type { MerchantUserInvitation } from './models/MerchantUserInvitation';
export type { MerchantUserPrivilege } from './models/MerchantUserPrivilege';
export type { MobileApiShipment } from './models/MobileApiShipment';
export type { NoAvatar } from './models/NoAvatar';
export type { NumberOrZero } from './models/NumberOrZero';
export type { OaService } from './models/OaService';
export type { OfflineStore } from './models/OfflineStore';
export type { OfflineStoreInput } from './models/OfflineStoreInput';
export type { Omit_BillingAddressCompanyV2_defaultBillingAddress_ } from './models/Omit_BillingAddressCompanyV2_defaultBillingAddress_';
export type { Omit_BillingAddressPersonalV2_defaultBillingAddress_ } from './models/Omit_BillingAddressPersonalV2_defaultBillingAddress_';
export type { Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_ } from './models/Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_';
export type { Omit_DocumentDetails_id_ } from './models/Omit_DocumentDetails_id_';
export type { Omit_OfflineStore_offlineStoreId_or_merchantId_ } from './models/Omit_OfflineStore_offlineStoreId_or_merchantId_';
export type { OnboardingTranslatedConsent } from './models/OnboardingTranslatedConsent';
export type { OpenPayBasket } from './models/OpenPayBasket';
export type { OrderBasketv2 } from './models/OrderBasketv2';
export type { OrderConsent } from './models/OrderConsent';
export type { OrderDeliveryMethodv2 } from './models/OrderDeliveryMethodv2';
export type { OrderFailure } from './models/OrderFailure';
export type { OrderFailureMeta } from './models/OrderFailureMeta';
export type { OrderFailureType } from './models/OrderFailureType';
export type { OrderProduct } from './models/OrderProduct';
export type { OrderReturnPolicy } from './models/OrderReturnPolicy';
export type { OrderStatus } from './models/OrderStatus';
export type { OtherBanksEnum } from './models/OtherBanksEnum';
export type { Paginated_AppWalletTrx_ } from './models/Paginated_AppWalletTrx_';
export type { Paginated_MerchantInvoice_ } from './models/Paginated_MerchantInvoice_';
export type { Paginated_MerchantOrder_ } from './models/Paginated_MerchantOrder_';
export type { Paginated_MerchantUser_ } from './models/Paginated_MerchantUser_';
export type { Paginated_MerchantUserInvitation_or_MerchantUserCredentialChange_ } from './models/Paginated_MerchantUserInvitation_or_MerchantUserCredentialChange_';
export type { Paginated_Settlement_ } from './models/Paginated_Settlement_';
export type { Partial_IntegrationProfileUrls_ } from './models/Partial_IntegrationProfileUrls_';
export type { PaymentHistoryItem } from './models/PaymentHistoryItem';
export type { PaymentHistoryItemAuthorized } from './models/PaymentHistoryItemAuthorized';
export type { PaymentHistoryItemPayment } from './models/PaymentHistoryItemPayment';
export type { PaymentHistoryItemRefund } from './models/PaymentHistoryItemRefund';
export type { PaymentHistoryItemRefundV2 } from './models/PaymentHistoryItemRefundV2';
export type { PaymentHistoryItemV1 } from './models/PaymentHistoryItemV1';
export type { PaymentStatus } from './models/PaymentStatus';
export type { PayoutConfig } from './models/PayoutConfig';
export type { PermissiveHttpsUrl } from './models/PermissiveHttpsUrl';
export type { PgOrder } from './models/PgOrder';
export type { PgWalletTrx } from './models/PgWalletTrx';
export type { Pick_BillingAddressCompanyV2_Exclude_keyofBillingAddressCompanyV2_defaultBillingAddress__ } from './models/Pick_BillingAddressCompanyV2_Exclude_keyofBillingAddressCompanyV2_defaultBillingAddress__';
export type { Pick_BillingAddressPersonalV2_Exclude_keyofBillingAddressPersonalV2_defaultBillingAddress__ } from './models/Pick_BillingAddressPersonalV2_Exclude_keyofBillingAddressPersonalV2_defaultBillingAddress__';
export type { Pick_CachedBasketProductv3_Exclude_keyofCachedBasketProductv3_images_or_prices__ } from './models/Pick_CachedBasketProductv3_Exclude_keyofCachedBasketProductv3_images_or_prices__';
export type { Pick_ConsentDefinition_Exclude_keyofConsentDefinition_version_or_consentDefinitionId_or_ownerId__ } from './models/Pick_ConsentDefinition_Exclude_keyofConsentDefinition_version_or_consentDefinitionId_or_ownerId__';
export type { Pick_DocumentDetails_Exclude_keyofDocumentDetails_id__ } from './models/Pick_DocumentDetails_Exclude_keyofDocumentDetails_id__';
export type { Pick_IntegrationProfile_integrationProfileName_or_websiteUrl_or_logoUrl_ } from './models/Pick_IntegrationProfile_integrationProfileName_or_websiteUrl_or_logoUrl_';
export type { Pick_Location_Exclude_keyofLocation_merchantId__ } from './models/Pick_Location_Exclude_keyofLocation_merchantId__';
export type { Pick_MobileApiOrderV2_Exclude_keyofMobileApiOrderV2_userId_or_ulid_or_userEmail_or_payment_or_statusLabel_or_orderPaymentInfo__ } from './models/Pick_MobileApiOrderV2_Exclude_keyofMobileApiOrderV2_userId_or_ulid_or_userEmail_or_payment_or_statusLabel_or_orderPaymentInfo__';
export type { Pick_OfflineStore_Exclude_keyofOfflineStore_offlineStoreId_or_merchantId__ } from './models/Pick_OfflineStore_Exclude_keyofOfflineStore_offlineStoreId_or_merchantId__';
export type { Pick_RefundCallbackRequest_Exclude_keyofRefundCallbackRequest_currency_or_amount_or_caseId__ } from './models/Pick_RefundCallbackRequest_Exclude_keyofRefundCallbackRequest_currency_or_amount_or_caseId__';
export type { QueryParam } from './models/QueryParam';
export type { QuerySpec } from './models/QuerySpec';
export type { RangeClause } from './models/RangeClause';
export type { Record_string_any_ } from './models/Record_string_any_';
export type { Record_string_ServiceSummary_ } from './models/Record_string_ServiceSummary_';
export type { Record_string_TaxSummary_ } from './models/Record_string_TaxSummary_';
export type { RefundOrderRequest } from './models/RefundOrderRequest';
export type { RefundOrderRequestV2 } from './models/RefundOrderRequestV2';
export type { RefundProduct } from './models/RefundProduct';
export type { RefundResult } from './models/RefundResult';
export type { ReturnLocation } from './models/ReturnLocation';
export type { Settlement } from './models/Settlement';
export type { SettlementStatus } from './models/SettlementStatus';
export type { ShipmentProduct } from './models/ShipmentProduct';
export type { StatusCategory } from './models/StatusCategory';
export type { StatusLabel } from './models/StatusLabel';
export type { SupportedLanguage } from './models/SupportedLanguage';
export type { TestGetBasketConnectionRequest } from './models/TestGetBasketConnectionRequest';
export type { TopupDirectDebitResponseItemV2 } from './models/TopupDirectDebitResponseItemV2';
export type { TopupDirectDebitResponseV3 } from './models/TopupDirectDebitResponseV3';
export type { TransactionMethod } from './models/TransactionMethod';
export type { Transactions } from './models/Transactions';
export type { TransactionSummary } from './models/TransactionSummary';
export type { Translations_DeliveryOptionConfigTranslationModel_ } from './models/Translations_DeliveryOptionConfigTranslationModel_';
export type { TwoFactorConfirmRequest } from './models/TwoFactorConfirmRequest';
export type { UpdateIntegrationProfileRequest } from './models/UpdateIntegrationProfileRequest';
export type { UpdateLocationRequest } from './models/UpdateLocationRequest';
export type { UrlString } from './models/UrlString';
export type { UserAvatar } from './models/UserAvatar';
export type { UserTransactionParty } from './models/UserTransactionParty';
export type { VerifyEmailInitRequest } from './models/VerifyEmailInitRequest';
export type { VerifyPhoneInitRequest } from './models/VerifyPhoneInitRequest';
export type { WalletAvatar } from './models/WalletAvatar';
export type { WalletCounterPartyBank } from './models/WalletCounterPartyBank';
export type { WalletCounterPartyBLIK } from './models/WalletCounterPartyBLIK';
export type { WalletCounterPartyCard } from './models/WalletCounterPartyCard';
export type { WalletCounterPartyMerchant } from './models/WalletCounterPartyMerchant';
export type { WalletCounterPartyOpenApp } from './models/WalletCounterPartyOpenApp';
export type { WalletCounterPartyWallet } from './models/WalletCounterPartyWallet';
export type { WalletLimits } from './models/WalletLimits';
export type { WalletSelfTransactionParty } from './models/WalletSelfTransactionParty';
export type { WalletTransactionMethod } from './models/WalletTransactionMethod';
export type { WalletTransactionParty } from './models/WalletTransactionParty';
export type { WalletTrxSubType } from './models/WalletTrxSubType';
export type { WebshopPlatform } from './models/WebshopPlatform';
export type { WebshopPlatformBody } from './models/WebshopPlatformBody';
export type { WebshopPlatformPluginStatus } from './models/WebshopPlatformPluginStatus';

export { ConsentDefinitionsService } from './services/ConsentDefinitionsService';
export { DeliveryProviderService } from './services/DeliveryProviderService';
export { IntegrationProfilesService } from './services/IntegrationProfilesService';
export { LocationsService } from './services/LocationsService';
export { LoginService } from './services/LoginService';
export { OfflineStoresService } from './services/OfflineStoresService';
export { OnboardingService } from './services/OnboardingService';
export { UserService } from './services/UserService';
export { UserInviteService } from './services/UserInviteService';
