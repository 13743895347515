import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { map } from 'rxjs/operators';
import { LoginService, MerchantUserPrivilege } from 'app/api/generated';
import { UserExt } from '../user/user.types';
import { MerchantUserPrivilegeEnum } from 'app/shared/enums/enums';

const rootPrivilege = 'MERCHANT_ADMIN__' as MerchantUserPrivilege;

@Injectable()
export class AuthService {
  private readonly TRUSTED_BROWSER_KEY = 'isTrustedBrowser';
  private readonly ACCESS_TOKEN = 'accessToken';

  userPermissions: MerchantUserPrivilege[] = [];

  constructor(
    private readonly _httpClient: HttpClient, 
    private readonly _userService: UserService,
    private readonly loginService: LoginService,
  ) {}

  getToken(): string | null {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  setToken(token: string) {
    localStorage.setItem(this.ACCESS_TOKEN, token);
  }

  signOut(): Observable<never> {
    localStorage.removeItem(this.ACCESS_TOKEN);
    return EMPTY;
  }

  isAuthenticated(): Observable<boolean> {
    const token = this.getToken();
    if (!token || AuthUtils.isTokenExpired(token)) {
      return of(false);
    }
    return of(true);
  }

  refreshToken(): Observable<{merchantLoginToken: string}> {
    return this.loginService.refresh();
  }

  isAuthorized(permissions: MerchantUserPrivilege[]): Observable<boolean> {
    return this._userService.user$.pipe(
      map((user: UserExt) => {
        const { merchantUserPrivileges: privileges } = user.merchantUser;
        this.userPermissions = privileges;

        if (!permissions) {
          return true;
        }

        if (privileges.includes(rootPrivilege)) {
          return true;
        }

        const isAuthorized = permissions.some(element => privileges.includes(element));
        return isAuthorized;
      })
    );
  }

  getTrustedBrowserToken() {
    return localStorage.getItem(this.TRUSTED_BROWSER_KEY);
  }

  removeTrustedBrowserKey() {
    localStorage.removeItem(this.TRUSTED_BROWSER_KEY);
  }

  setTrustedBrowserKey(token: string) {
    localStorage.setItem(this.TRUSTED_BROWSER_KEY, token);
  }

  getUserRoles() {
    return this._userService.user.merchantUser;
  }
}
