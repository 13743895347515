/**
 * AuthInterceptor intercepts outgoing HTTP requests to handle authentication.
 * - Adds a Bearer token to requests if available and valid.
 * - Automatically refreshes expired tokens before sending requests.
 * - Handles error scenarios, such as unauthorized responses (401).
 * - Adjusts request configurations for specific API endpoints (e.g., response type modifications).
 */
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly TOKEN_REFRESH_THRESHOLD_SECONDS = 300;

  constructor(private authService: AuthService) {}

  /**
   * Intercepts and modifies outgoing HTTP requests.
   * - Adds an authorization token if available and valid.
   * - Refreshes the token if it is close to expiration.
   * - Modifies specific requests based on their URL patterns.
   *
   * @param req The outgoing HTTP request.
   * @param next The HTTP handler to pass the request to the next step in the pipeline.
   * @returns An Observable of the HTTP event.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq = req.clone();
    let authToken = this.authService.getToken();

    // Refresh the token if expired or near expiration
    if (
      authToken &&
      AuthUtils.isTokenExpired(authToken, this.TOKEN_REFRESH_THRESHOLD_SECONDS) &&
      !req.url.includes('/login')
    ) {
      return this.authService.refreshToken().pipe(
        switchMap((response: { merchantLoginToken: string }) => {
          if (response.merchantLoginToken) {
            authToken = response.merchantLoginToken;
            this.authService.setToken(authToken);
          }
          const clonedRequest = this.cloneRequestWithToken(req, authToken);
          return next.handle(clonedRequest);
        }),
        catchError((error) => this.handleError(error, req, next))
      );
    }

    // Add Authorization header if the token is valid
    if (authToken && !AuthUtils.isTokenExpired(authToken)) {
      newReq = req.clone({
        headers: new HttpHeaders({
          Authorization: `Bearer ${authToken}`,
        }),
      });
    }

    // Handle specific URL patterns
    newReq = this.processRequest(req, newReq);

    return next.handle(newReq).pipe(
      catchError((error) => this.handleError(error, req, next))
    );
  }

  /**
   * Processes requests with specific patterns and modifies their configurations.
   *
   * @param req The original HTTP request.
   * @param newReq The modified HTTP request.
   * @returns The modified HTTP request with adjusted settings for specific endpoints.
   */
  private processRequest(req: HttpRequest<any>, newReq: HttpRequest<any>): HttpRequest<any> {
    const patterns = {
      refund: /merchantPanel\/v1\/integrationProfiles\/[\S]{0,}\/refund$/,
      paymentHistory: /merchantPanel\/v1\/integrationProfiles\/[\S]{0,}\/order\/[\S]{0,}\/refund\/[\S]{0,}\/pdf$/,
    };

    if (patterns.paymentHistory.test(req.url)) {
      return newReq.clone({ responseType: 'blob' });
    }

    if (patterns.refund.test(req.url)) {
      return newReq.clone({ responseType: 'text' });
    }

    return newReq;
  }

  /**
   * Clones an HTTP request and adds an Authorization header with the given token.
   *
   * @param req The original HTTP request.
   * @param token The authentication token.
   * @returns A cloned HTTP request with the Authorization header added.
   */
  private cloneRequestWithToken(req: HttpRequest<any>, token: string | null): HttpRequest<any> {
    if (token) {
      return req.clone({
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`,
        }),
      });
    }
    return req;
  }

  /**
   * Handles errors encountered during HTTP requests.
   * - Logs the user out and reloads the page if unauthorized (401).
   *
   * @param error The HTTP error response.
   * @param req The original HTTP request.
   * @param next The HTTP handler.
   * @returns An Observable of the error to propagate it further.
   */
  private handleError(
    error: HttpErrorResponse,
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse && error.status === 401) {
      this.authService.signOut();
      location.reload();
    }
    return throwError(error?.error || error);
  }
}
