/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { Amount } from '../models/Amount';
import type { APIGatewayProxyResult } from '../models/APIGatewayProxyResult';
import type { AppWalletTrx } from '../models/AppWalletTrx';
import type { CreateCredentialOneTimeResponse } from '../models/CreateCredentialOneTimeResponse';
import type { CreateCredentialRequest } from '../models/CreateCredentialRequest';
import type { IntegrationProfileResponse } from '../models/IntegrationProfileResponse';
import type { LocalDateTime } from '../models/LocalDateTime';
import type { MerchantOrder } from '../models/MerchantOrder';
import type { OaService } from '../models/OaService';
import type { OpenPayBasket } from '../models/OpenPayBasket';
import type { OrderFailure } from '../models/OrderFailure';
import type { Paginated_AppWalletTrx_ } from '../models/Paginated_AppWalletTrx_';
import type { Paginated_MerchantInvoice_ } from '../models/Paginated_MerchantInvoice_';
import type { Paginated_MerchantOrder_ } from '../models/Paginated_MerchantOrder_';
import type { Paginated_Settlement_ } from '../models/Paginated_Settlement_';
import type { PgOrder } from '../models/PgOrder';
import type { PgWalletTrx } from '../models/PgWalletTrx';
import type { Pick_IntegrationProfile_integrationProfileName_or_websiteUrl_or_logoUrl_ } from '../models/Pick_IntegrationProfile_integrationProfileName_or_websiteUrl_or_logoUrl_';
import type { QuerySpec } from '../models/QuerySpec';
import type { Record_string_any_ } from '../models/Record_string_any_';
import type { RefundOrderRequestV2 } from '../models/RefundOrderRequestV2';
import type { RefundResult } from '../models/RefundResult';
import type { TestGetBasketConnectionRequest } from '../models/TestGetBasketConnectionRequest';
import type { TopupDirectDebitResponseV3 } from '../models/TopupDirectDebitResponseV3';
import type { UpdateIntegrationProfileRequest } from '../models/UpdateIntegrationProfileRequest';
import type { WalletLimits } from '../models/WalletLimits';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class IntegrationProfilesService {

    constructor(private readonly http: HttpClient) {}

    /**
     * Can only create a maximum of 20 active integration profiles per merchant
     * @param requestBody
     * @returns IntegrationProfileResponse Ok
     * @throws ApiError
     */
    public createIntegrationProfile(
        requestBody: Pick_IntegrationProfile_integrationProfileName_or_websiteUrl_or_logoUrl_,
    ): Observable<IntegrationProfileResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/integrationProfiles',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Exhaustive list of active integration profiles, the limit of which is 20
     * @returns IntegrationProfileResponse Ok
     * @throws ApiError
     */
    public listIntegrationProfiles(): Observable<Array<IntegrationProfileResponse>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/integrationProfiles',
        });
    }

    /**
     * @param integrationProfileId
     * @param requestBody
     * @returns IntegrationProfileResponse Ok
     * @throws ApiError
     */
    public updateIntegrationProfile(
        integrationProfileId: string,
        requestBody: UpdateIntegrationProfileRequest,
    ): Observable<IntegrationProfileResponse> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/integrationProfiles/{integrationProfileId}',
            path: {
                'integrationProfileId': integrationProfileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Permanently deactivates the profile
     * @param integrationProfileId
     * @returns void
     * @throws ApiError
     */
    public deactivateIntegrationProfile(
        integrationProfileId: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/integrationProfiles/{integrationProfileId}',
            path: {
                'integrationProfileId': integrationProfileId,
            },
        });
    }

    /**
     * Get the settlements for the merchant
     * @param integrationProfileId
     * @param last
     * @param limit
     * @returns Paginated_Settlement_ Ok
     * @throws ApiError
     */
    public getSettlementsForIntegrationProfile(
        integrationProfileId: string,
        last?: string,
        limit?: number,
    ): Observable<Paginated_Settlement_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/integrationProfiles/{integrationProfileId}/settlements',
            path: {
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @deprecated
     * Download the settlements for the merchant
     * @param integrationProfileId
     * @param startDate
     * @returns APIGatewayProxyResult Ok
     * @throws ApiError
     */
    public downloadSettlementForIntegrationProfile(
        integrationProfileId: string,
        startDate: string,
    ): Observable<APIGatewayProxyResult> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/integrationProfiles/{integrationProfileId}/settlements/{startDate}',
            path: {
                'integrationProfileId': integrationProfileId,
                'startDate': startDate,
            },
        });
    }

    /**
     * Download the settlements for the merchant
     * @param integrationProfileId
     * @param startDate
     * @param service
     * @returns APIGatewayProxyResult Ok
     * @throws ApiError
     */
    public downloadSettlementForIntegrationProfileV2(
        integrationProfileId: string,
        startDate: string,
        service: OaService,
    ): Observable<APIGatewayProxyResult> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/integrationProfiles/{integrationProfileId}/settlements/{startDate}/{service}',
            path: {
                'integrationProfileId': integrationProfileId,
                'startDate': startDate,
                'service': service,
            },
        });
    }

    /**
     * @deprecated
     * Get the orders for the merchant
     * @param integrationProfileId
     * @param last
     * @param limit
     * @returns Paginated_MerchantOrder_ Ok
     * @throws ApiError
     */
    public getOrdersForIntegrationProfile(
        integrationProfileId: string,
        last?: string,
        limit?: number,
    ): Observable<Paginated_MerchantOrder_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/integrationProfiles/{integrationProfileId}/orders',
            path: {
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @deprecated
     * Search the orders for the merchant
     * @param integrationProfileId
     * @param requestBody
     * @param offset
     * @param limit
     * @returns PgOrder Ok
     * @throws ApiError
     */
    public searchOrdersForIntegrationProfile(
        integrationProfileId: string,
        requestBody: QuerySpec,
        offset?: number,
        limit: number = 20,
    ): Observable<Array<PgOrder>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/integrationProfiles/{integrationProfileId}/orders',
            path: {
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @deprecated
     * Get an order for the merchant
     * @param integrationProfileId
     * @param shopOrderId
     * @returns MerchantOrder Ok
     * @throws ApiError
     */
    public getOrderForIntegrationProfile(
        integrationProfileId: string,
        shopOrderId: string,
    ): Observable<MerchantOrder> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/integrationProfiles/{integrationProfileId}/orders/{shopOrderId}',
            path: {
                'integrationProfileId': integrationProfileId,
                'shopOrderId': shopOrderId,
            },
        });
    }

    /**
     * @param integrationProfileId
     * @param requestBody
     * @returns RefundResult Ok
     * @throws ApiError
     */
    public refundOrdersForIntegrationProfileV2(
        integrationProfileId: string,
        requestBody: RefundOrderRequestV2,
    ): Observable<RefundResult> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v2/integrationProfiles/{integrationProfileId}/refund',
            path: {
                'integrationProfileId': integrationProfileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @deprecated
     * Get the wallet transactions for the merchant
     * @param integrationProfileId
     * @param last
     * @param limit
     * @returns Paginated_AppWalletTrx_ Ok
     * @throws ApiError
     */
    public getWalletTrxsForIntegrationProfile(
        integrationProfileId: string,
        last?: string,
        limit?: number,
    ): Observable<Paginated_AppWalletTrx_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/integrationProfiles/{integrationProfileId}/wallets',
            path: {
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @deprecated
     * Get the wallet details for the merchant wallet.
     * @param integrationProfileId
     * @returns any Details about the merchant wallet.
     * @throws ApiError
     */
    public getWalletDetails(
        integrationProfileId: string,
    ): Observable<{
        limits: WalletLimits;
        balance: Amount;
        topups: TopupDirectDebitResponseV3;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/integrationProfiles/{integrationProfileId}/wallet/details',
            path: {
                'integrationProfileId': integrationProfileId,
            },
        });
    }

    /**
     * Get the invoices for the merchant
     * @param integrationProfileId
     * @param last
     * @param limit
     * @returns Paginated_MerchantInvoice_ Ok
     * @throws ApiError
     */
    public getInvoicesForIntegrationProfile(
        integrationProfileId: string,
        last?: string,
        limit?: number,
    ): Observable<Paginated_MerchantInvoice_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/integrationProfiles/{integrationProfileId}/invoices',
            path: {
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * Download an invoice
     * @param integrationProfileId
     * @param id
     * @returns APIGatewayProxyResult Ok
     * @throws ApiError
     */
    public downloadInvoiceForIntegrationProfile(
        integrationProfileId: string,
        id: string,
    ): Observable<APIGatewayProxyResult> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/integrationProfiles/{integrationProfileId}/invoices/{id}',
            path: {
                'integrationProfileId': integrationProfileId,
                'id': id,
            },
        });
    }

    /**
     * @param integrationProfileId
     * @param shopOrderId
     * @param id
     * @returns APIGatewayProxyResult Ok
     * @throws ApiError
     */
    public downloadRefundPdf(
        integrationProfileId: string,
        shopOrderId: string,
        id: string,
    ): Observable<APIGatewayProxyResult> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/integrationProfiles/{integrationProfileId}/order/{shopOrderId}/refund/{id}/pdf',
            path: {
                'integrationProfileId': integrationProfileId,
                'shopOrderId': shopOrderId,
                'id': id,
            },
        });
    }

    /**
     * Can only create a maximum of 20 active credentials per integration profile
     * @param integrationProfileId
     * @param requestBody
     * @returns CreateCredentialOneTimeResponse Ok
     * @throws ApiError
     */
    public createCredential(
        integrationProfileId: string,
        requestBody: CreateCredentialRequest,
    ): Observable<CreateCredentialOneTimeResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/integrationProfiles/{integrationProfileId}/openAppCredentials',
            path: {
                'integrationProfileId': integrationProfileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param integrationProfileId
     * @param credentialId
     * @returns void
     * @throws ApiError
     */
    public deleteCredential(
        integrationProfileId: string,
        credentialId: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/integrationProfiles/{integrationProfileId}/openAppCredentials/{credentialId}',
            path: {
                'integrationProfileId': integrationProfileId,
                'credentialId': credentialId,
            },
        });
    }

    /**
     * @param integrationProfileId
     * @param requestBody
     * @returns OpenPayBasket Ok
     * @throws ApiError
     */
    public testGetBasketConnection(
        integrationProfileId: string,
        requestBody: TestGetBasketConnectionRequest,
    ): Observable<OpenPayBasket> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/integrationProfiles/{integrationProfileId}/testConnection/getBasket',
            path: {
                'integrationProfileId': integrationProfileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param integrationProfileId
     * @param basketId
     * @returns Record_string_any_ Ok
     * @throws ApiError
     */
    public getBasketLogs(
        integrationProfileId: string,
        basketId: string,
    ): Observable<Array<Record_string_any_>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/integrationProfiles/{integrationProfileId}/getBasket/{basketId}/logs',
            path: {
                'integrationProfileId': integrationProfileId,
                'basketId': basketId,
            },
        });
    }

    /**
     * Get the orders for the merchant
     * @param integrationProfileId
     * @param last
     * @param limit
     * @returns Paginated_MerchantOrder_ Ok
     * @throws ApiError
     */
    public getOrdersForIntegrationProfile1(
        integrationProfileId: string,
        last?: string,
        limit?: number,
    ): Observable<Paginated_MerchantOrder_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/orders/profile/{integrationProfileId}',
            path: {
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * Search the orders for the merchant
     * @param integrationProfileId
     * @param requestBody
     * @param offset
     * @param limit
     * @returns PgOrder Ok
     * @throws ApiError
     */
    public searchOrdersForIntegrationProfile1(
        integrationProfileId: string,
        requestBody: QuerySpec,
        offset?: number,
        limit: number = 20,
    ): Observable<Array<PgOrder>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/orders/profile/{integrationProfileId}',
            path: {
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get an order for the merchant
     * @param integrationProfileId
     * @param shopOrderId
     * @returns MerchantOrder Ok
     * @throws ApiError
     */
    public getOrderForIntegrationProfile1(
        integrationProfileId: string,
        shopOrderId: string,
    ): Observable<MerchantOrder> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/orders/profile/{integrationProfileId}/orders/{shopOrderId}',
            path: {
                'integrationProfileId': integrationProfileId,
                'shopOrderId': shopOrderId,
            },
        });
    }

    /**
     * @param integrationProfileId
     * @param start
     * @param end
     * @param last
     * @param limit
     * @returns any Ok
     * @throws ApiError
     */
    public getOrderFailuresForIntegrationProfile(
        integrationProfileId: string,
        start?: LocalDateTime,
        end?: LocalDateTime,
        last?: string,
        limit: number = 20,
    ): Observable<{
        last: string;
        values: Array<OrderFailure>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/orders/profile/{integrationProfileId}/failures',
            path: {
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'start': start,
                'end': end,
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * Get the wallet details for the merchant wallet.
     * @param integrationProfileId
     * @returns any Details about the merchant wallet.
     * @throws ApiError
     */
    public getWalletDetailsV2(
        integrationProfileId: string,
    ): Observable<{
        limits: WalletLimits;
        balance: Amount;
        topups: TopupDirectDebitResponseV3;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v2/integrationProfiles/{integrationProfileId}/wallet/details',
            path: {
                'integrationProfileId': integrationProfileId,
            },
        });
    }

    /**
     * Search the wallet transactions for the merchant
     * @param integrationProfileId
     * @param requestBody
     * @param offset
     * @param limit
     * @returns PgWalletTrx Ok
     * @throws ApiError
     */
    public searchWalletTrxForIntegrationProfile(
        integrationProfileId: string,
        requestBody: QuerySpec,
        offset?: number,
        limit: number = 20,
    ): Observable<Array<PgWalletTrx>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/integrationProfiles/{integrationProfileId}/wallet',
            path: {
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a wallet transaction for the merchant
     * @param integrationProfileId
     * @param trxId
     * @returns AppWalletTrx Ok
     * @throws ApiError
     */
    public getWalletTrxForIntegrationProfile(
        integrationProfileId: string,
        trxId: number,
    ): Observable<AppWalletTrx> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/integrationProfiles/{integrationProfileId}/wallet/{trxId}',
            path: {
                'integrationProfileId': integrationProfileId,
                'trxId': trxId,
            },
        });
    }

}